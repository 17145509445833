import { type GetEvents_tournament_events } from 'src/graphql-types/GetEvents';
import { GetEventsOverview_tournament_events } from 'src/graphql-types/GetEventsOverview';

import { orderEvents } from '@clubspark-react/data-fns';

const getSortedEvents = (events?: (GetEvents_tournament_events | GetEventsOverview_tournament_events)[]) => {
  if (events) {
    const eventsMap = Object.fromEntries(Object.entries(events).map(([, event]) => [event.id, event]));
    const eventsDivisionData = events.map((event) => ({
      eventId: event.id,
      division: {
        ...event?.division,
      },
    }));
    const orderedEvents = orderEvents(eventsDivisionData);
    return orderedEvents.map(({ eventId }) => eventsMap[eventId]);
  }
};
export default getSortedEvents;
