import gql from 'graphql-tag';

export const GET_TOURNAMENTS = gql`
  query GetTournaments(
    $groupBy: GroupingType!
    $startDateFrom: String!
    $startDateTo: String!
    $orgId: UUID
    $filters: [PropertyFilterInput!]
  ) {
    paginatedTournamentGroups(
      paginationOptions: { limit: 53, offset: 0, filters: $filters }
      filter: {
        ignoreEmptyGroups: true
        startDateFrom: $startDateFrom
        startDateTo: $startDateTo
        groupBy: $groupBy
        organisationId: $orgId
      }
    ) {
      totalItems
      items {
        groupNumber
        groupingType
        startDate
        endDate
        tournaments {
          id
          identificationCode
          name
          eventCount
          sanctionStatus
          timings {
            startDate
            endDate
            timeZone
          }
          director {
            id
            firstName
            lastName
          }
          primaryLocation {
            id
            name
            county
          }
          level {
            id
            shortName
            category
          }
          organisation {
            id
            name
            hierarchy
            parent {
              id
              name
              parent {
                id
                name
              }
            }
          }
          websiteContent {
            logoPath
          }
          isPublished
          isCancelled
        }
      }
    }
  }
`;
