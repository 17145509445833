// extracted by mini-css-extract-plugin
export var actionsWrapper = "tournaments-module--actionsWrapper--8390a";
export var bodyBase = "tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var bodyLarge = "tournaments-module--body-large--1fb3e tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var bodyLargeBold = "tournaments-module--body-large-bold--9ccb0 tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var bodyRegular = "tournaments-module--body-regular--8e074 tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var bodyRegularBold = "tournaments-module--body-regular-bold--76ab3 tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var bodySmall = "tournaments-module--body-small--e871a tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var bodySmallBold = "tournaments-module--body-small-bold--e6017 tournaments-module--body-base--38cd2 tournaments-module--site-font--cc3d5";
export var borderTop = "tournaments-module--border-top--07bc9";
export var breakWordContainer = "tournaments-module--break-word-container--6fa62";
export var buttonIconBase = "tournaments-module--button-icon-base--31e1d";
export var buttonsContainer = "tournaments-module--buttonsContainer--7f0f6";
export var clickLink = "tournaments-module--click-link--7ba71";
export var datesColumn = "tournaments-module--dates-column--67b45";
export var dropdownBase = "tournaments-module--dropdown-base--88984";
export var dropdownSelectBase = "tournaments-module--dropdown-select-base--f806f tournaments-module--text-input--ea966";
export var eventsLink = "tournaments-module--eventsLink--2f24e";
export var eventsWidth = "tournaments-module--events-width--9960a tournaments-module--small-col-base--ac301";
export var flexCol = "tournaments-module--flex-col--53c88";
export var formErrorMessage = "tournaments-module--form-error-message--9d5f0";
export var h3 = "tournaments-module--h3--9f0a9";
export var h4 = "tournaments-module--h4--5aaad";
export var hoverLink = "tournaments-module--hover-link--bb056";
export var hoverRow = "tournaments-module--hover-row--28051";
export var id = "tournaments-module--id--844da";
export var levelWidth = "tournaments-module--level-width--fb1ee tournaments-module--small-col-base--ac301";
export var location = "tournaments-module--location--8a020";
export var membershipContainer = "tournaments-module--membership-container--3a787 tournaments-module--flex-col--53c88 tournaments-module--primary-border--af066";
export var membershipHeader = "tournaments-module--membership-header--81dfc";
export var membershipHeading = "tournaments-module--membership-heading--64655";
export var membershipLabel = "tournaments-module--membership-label--7ef0d";
export var moreFiltersBorderClass = "tournaments-module--more-filters-border-class--93973";
export var name = "tournaments-module--name--45beb";
export var nameAndLocation = "tournaments-module--name-and-location--184be";
export var nameAndLocationWidth = "tournaments-module--name-and-location-width--49563";
export var pageBg = "tournaments-module--page-bg--b5e31";
export var pointer = "tournaments-module--pointer--0b840";
export var primaryBorder = "tournaments-module--primary-border--af066";
export var shadowBoxLight = "tournaments-module--shadow-box-light--2286e";
export var siteFont = "tournaments-module--site-font--cc3d5";
export var slideDownAndFade = "tournaments-module--slideDownAndFade--dfcf6";
export var slideLeftAndFade = "tournaments-module--slideLeftAndFade--4d6be";
export var slideRightAndFade = "tournaments-module--slideRightAndFade--9b107";
export var slideUpAndFade = "tournaments-module--slideUpAndFade--c1f2c";
export var smallColBase = "tournaments-module--small-col-base--ac301";
export var statusDecoration = "tournaments-module--status-decoration--440be";
export var textInput = "tournaments-module--text-input--ea966";
export var textInverted = "tournaments-module--text-inverted--5a62b";
export var textMediumDark = "tournaments-module--text-medium-dark--54529";
export var titleDate = "tournaments-module--title-date--744fa";
export var tooltipFont = "tournaments-module--tooltipFont--3217b";
export var tournamentLogo = "tournaments-module--tournament-logo--12066 tournaments-module--tournament-logo-base--b0734";
export var tournamentLogoBase = "tournaments-module--tournament-logo-base--b0734";
export var tournamentLogoPlaceholder = "tournaments-module--tournament-logo-placeholder--69478 tournaments-module--tournament-logo-base--b0734";
export var unstyledButton = "tournaments-module--unstyled-button--9f9fa";