// extracted by mini-css-extract-plugin
export var body = "tournament-events-modal-module--body--81cb2";
export var bodyBase = "tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var bodyLarge = "tournament-events-modal-module--body-large--1e04e tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var bodyLargeBold = "tournament-events-modal-module--body-large-bold--50598 tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var bodyRegular = "tournament-events-modal-module--body-regular--075aa tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var bodyRegularBold = "tournament-events-modal-module--body-regular-bold--2795d tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var bodySmall = "tournament-events-modal-module--body-small--ab278 tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var bodySmallBold = "tournament-events-modal-module--body-small-bold--d9f06 tournament-events-modal-module--body-base--3adfe tournament-events-modal-module--site-font--d7804";
export var borderTop = "tournament-events-modal-module--border-top--88dbe";
export var breakWordContainer = "tournament-events-modal-module--break-word-container--473c2";
export var buttonIconBase = "tournament-events-modal-module--button-icon-base--d8121";
export var clickLink = "tournament-events-modal-module--click-link--3b021";
export var dropdownBase = "tournament-events-modal-module--dropdown-base--62ae4";
export var dropdownSelectBase = "tournament-events-modal-module--dropdown-select-base--5e22e tournament-events-modal-module--text-input--b9143";
export var eventsCount = "tournament-events-modal-module--eventsCount--4aaf5";
export var flexCol = "tournament-events-modal-module--flex-col--0c91f";
export var formErrorMessage = "tournament-events-modal-module--form-error-message--29fb3";
export var h3 = "tournament-events-modal-module--h3--bca7c";
export var h4 = "tournament-events-modal-module--h4--bf8f6";
export var hoverLink = "tournament-events-modal-module--hover-link--d56e5";
export var hoverRow = "tournament-events-modal-module--hover-row--91891";
export var membershipContainer = "tournament-events-modal-module--membership-container--a1557 tournament-events-modal-module--flex-col--0c91f tournament-events-modal-module--primary-border--cad38";
export var membershipHeader = "tournament-events-modal-module--membership-header--4aef7";
export var membershipHeading = "tournament-events-modal-module--membership-heading--01496";
export var membershipLabel = "tournament-events-modal-module--membership-label--2ab48";
export var modalTitle = "tournament-events-modal-module--modalTitle--72a4d";
export var moreFiltersBorderClass = "tournament-events-modal-module--more-filters-border-class--8b54e";
export var pageBg = "tournament-events-modal-module--page-bg--1954a";
export var pointer = "tournament-events-modal-module--pointer--0aaca";
export var primaryBorder = "tournament-events-modal-module--primary-border--cad38";
export var shadowBoxLight = "tournament-events-modal-module--shadow-box-light--358a0";
export var siteFont = "tournament-events-modal-module--site-font--d7804";
export var slideDownAndFade = "tournament-events-modal-module--slideDownAndFade--cb3fe";
export var slideLeftAndFade = "tournament-events-modal-module--slideLeftAndFade--8df2b";
export var slideRightAndFade = "tournament-events-modal-module--slideRightAndFade--7e9ad";
export var slideUpAndFade = "tournament-events-modal-module--slideUpAndFade--70a0c";
export var statusDecoration = "tournament-events-modal-module--status-decoration--0e5e8";
export var textInput = "tournament-events-modal-module--text-input--b9143";
export var textInverted = "tournament-events-modal-module--text-inverted--ea0a8";
export var textMediumDark = "tournament-events-modal-module--text-medium-dark--261df";
export var tooltipFont = "tournament-events-modal-module--tooltipFont--e6051";
export var unstyledButton = "tournament-events-modal-module--unstyled-button--16a23";