import gql from 'graphql-tag';

export const GET_EVENTS = gql`
  query GetEvents($id: UUID!) {
    tournament(id: $id) {
      id
      sanctionStatus
      name
      tags
      visibilityMask
      tournamentFee
      finalisedAndCharged
      isPublished
      isCancelled
      tournamentFeePayment {
        status
        tournamentFee
        latestChargeTimestamp
        paymentMethod {
          id
        }
      }
      organisation {
        id
        name
        urlSegment
        hierarchy
      }
      director {
        id
        firstName
        lastName
      }
      lastSanctionStatusChange(sanctionStatus: SUBMITTED) {
        createdAt
        createdByFirstName
        createdByLastName
      }
      primaryLocation {
        id
        name
      }
      timings {
        startDate
        endDate
        timeZone
      }
      websiteContent {
        logoPath
      }
      level {
        id
        name
      }
      events {
        id
        sanctionStatus
        level {
          id
          name
          shortName
          category
        }
        formatConfiguration {
          ballColour
          drawSize
          eventFormat
          scoreFormat
        }
        division {
          ageCategory {
            maximumAge
            minimumAge
            type
          }
          gender
          eventType
          familyType
          ratingCategory {
            ratingCategoryType
            ratingType
            value
            minimumValue
            maximumValue
          }
          wheelchairRating
          ballColour
        }
        timings {
          startDate
          endDate
          timeZone
          lengthInDays
          hasNonConsecutiveDays
        }
        teamEventConfiguration {
          eventFormat
          maximumTeams
        }
        pricing {
          entryFee {
            amount
            currency
          }
        }
        surface
      }
      featureSettings {
        enabled
        featureId
        overridable
      }
    }
  }
`;

export const GET_EVENTS_OVERVIEW = gql`
  query GetEventsOverview($id: UUID!) {
    tournament(id: $id) {
      events {
        id
        level {
          category
        }
        division {
          ageCategory {
            maximumAge
            minimumAge
            type
          }
          gender
          eventType
          familyType
          ratingCategory {
            ratingCategoryType
            ratingType
            value
            minimumValue
            maximumValue
          }
          wheelchairRating
          ballColour
        }
        formatConfiguration {
          drawSize
        }
      }
    }
  }
`;

export const UPDATE_SANCTION_STATUS = gql`
  mutation UpdateSanction(
    $id: UUID!
    $approved: [EventApproved!]!
    $declined: [EventDeclined!]!
    $message: String
    $orgId: UUID!
    $processPayment: Boolean
  ) {
    updateTournamentSanctionStatus(
      tournamentId: $id
      sanctioningOrganisationId: $orgId
      approved: $approved
      declined: $declined
      message: $message
      processPayment: $processPayment
    ) {
      id
      sanctionStatus
      isPublished
      events {
        id
        sanctionStatus
      }
      tournamentFeePayment {
        status
        latestChargeTimestamp
      }
    }
  }
`;

export const RESET_SANCTION_STATUS = gql`
  mutation ResetSanctionStatus($tournamentId: UUID!, $orgId: UUID!, $message: String) {
    resetTournamentSanctionStatus(tournamentId: $tournamentId, sanctioningOrganisationId: $orgId, message: $message) {
      id
      sanctionStatus
    }
  }
`;
export const ADD_TOURNAMENT_TAG = gql`
  mutation AddTagToTournament($tournamentId: UUID!, $tag: String!) {
    addTagToTournament(tournamentId: $tournamentId, tag: $tag) {
      id
      tags
    }
  }
`;

export const DELETE_TOURNAMENT_TAG = gql`
  mutation DeleteTournamentTag($tournamentId: UUID!, $tag: String!) {
    deleteTagFromTournament(tournamentId: $tournamentId, tag: $tag) {
      id
      tags
    }
  }
`;

export const SET_TOURNAMENT_FEATURE = gql`
  mutation SetTournamentFeature($tournamentId: UUID!, $featureId: String!, $enabled: Boolean!) {
    setTournamentFeatureSettings(enabled: $enabled, tournamentId: $tournamentId, featureId: $featureId) {
      enabled
      featureId
      overridable
    }
  }
`;
