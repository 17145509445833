import React from 'react';

import { useTranslation } from 'react-i18next';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import { SanctionStatus } from 'src/graphql-types/globalTournamentTypes';

interface Props {
  status: SanctionStatus | null;
  cancelled?: boolean;
}

const SanctionStatusLabel: React.FC<Props> = ({ status, cancelled }) => {
  const { t } = useTranslation();
  let variety: LabelVariety = 'warning';
  let level = 'pending';

  if (status === 'APPROVED') {
    variety = 'success';
    level = 'approved';
  } else if (status === 'DECLINED') {
    variety = 'error';
    level = 'declined';
  }
  return (
    <>
      <StatusLabel variety={variety}>{t(level)}</StatusLabel>
      {
        cancelled && <StatusLabel variety={'error'}>{t('canceled')}</StatusLabel> // US spelling 'canceled'
      }
    </>
  );
};

export default SanctionStatusLabel;
