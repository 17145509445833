import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import Tournaments from 'src/components/tournaments/tournaments';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Tournaments" />
      <PrivateRoute>
        <Tournaments />
      </PrivateRoute>
    </Layout>
  );
};

export default IndexPage;
